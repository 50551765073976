import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Seo from "../components/Seo"
import "../styles/global.css"
import * as styles from "../styles/home.module.css"
import * as cardStyles from "../styles/plants.module.css"

export default function Home({ data }) {

  const plants = data.allPlantsDataJson.nodes

  const openLink = () => {
    window.open("https://it.wikipedia.org/wiki/Driadi", "_blank")
  }

  return (
    <div className={styles.container}>
      <Seo title='Cura e Coltivazione di Piante e Fiori' description='Guide complete su come prendersi cura delle proprie piante e fiori da appartamento o da esterno.' image={plants[0].image.childImageSharp.gatsbyImageData.images.fallback.src} article={false} />

      <div className={styles.titleContainer}>
        <h1 className={styles.title}>
          <span>Il Giardino delle</span>
          <a onClick={openLink} className={styles.link}>Driadi</a>
        </h1>
      </div>

      <Link to='/piante' className={styles.subtitleContainer}>
        <p className={styles.subtitle}>Vai alle piante</p>
        <img className={styles.chevron} src='/next.png' />
      </Link>


      <h3 className={styles.explore}>Esplora</h3>

      <div className={styles.plantsContainer}>
        {plants.map(plant => {
          return (<Link to={'/piante/' + plant.slug} key={plant.plantId}>
            <div className={cardStyles.card}>
              {plant.image.childImageSharp &&
                <GatsbyImage image={plant.image.childImageSharp.gatsbyImageData} className={cardStyles.image} src={plant.image} alt={plant.name} />
              }
              <p className={cardStyles.text}>{plant.name}</p>
            </div>
          </Link>)
        })}
      </div>

      <footer className={styles.footerContainer}>
        <p className={styles.footerText}>@ Copyright 2022 Francesco Leoni</p>
      </footer>
    </div>
  )
}

export const query = graphql`
query ExplorePlants {
  allPlantsDataJson(
    limit: 5
    filter: {isAvailable: {eq: true}}
    sort: {fields: creationDate, order: DESC}
  ) {
    nodes {
      slug
      name
      isAvailable
      plantId
      image {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
}
`